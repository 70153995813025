body {
  margin: 0px;
  padding: 0px ! important;
  font-family: 'Poppins', sans-serif;
}

html {
  scroll-behavior: smooth;
  transition: 0.5s;
}

@import "rsuite/dist/rsuite.css";

p {
  margin: 0px;
}

h3 {
  margin: 0px;
}

/* header css open here */

.ethapp {
  padding: 10px 15px;
  width: 135px;
  text-align: center;
  border-radius: 32px;
  color: #000 ! important;
  transition: 0.5s;
  margin-top: 0px ! important;
  background-color: #76b1fc;

}

.join-us {
  width: 42px;
  height: 42px;
  background-color: #ff8400;
  text-align: center;
  font-size: 12px ! important;
  line-height: 15px;
  padding: 6px;
  border-radius: 100%;
  margin-top: 0px ! important;
}

.header-c1 {
  align-self: center;
}

.ethapp:hover {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
  transition: 0.5s;
  text-decoration: none;
}

.burgers>img {
  width: 38px;
}

.logo-box>a {
  display: inline-block;
}

.burger-area {
  display: flex;
  display: none;
}

.mobile-header {
  display: none ! important;
}

.list-header2 {
  margin: 0px;
  padding: 0px;
  list-style: none;
  align-self: center;
  margin-left: 20px;
}

.logo-box>a>img {
  width: 98px;
}

.list-header {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
}

.list-header>li>p {
  font-weight: 700;
  color: #fff;
  font-size: 15px;
}

.list-header>li {
  margin-left: 20px;
  align-self: center;
}

.list-header>li>p>img {
  width: 30px;
  margin-right: 10px;
}

.connectlist {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
}

.connectlist>li>p {
  margin: 0px;
  font-size: 16px;
  color: #fff;
}

.connectlist>li>p>a {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  padding: 6px 20px;
  border: 1px solid #fff;
  background-color: #164b6a;
  border-radius: 32px;
  transition: 0.5s;
  vertical-align: middle;
}

.connectlist>li>p>a:hover {
  color: #fccb20;
  background-color: #000;
  transition: 0.5s;
}

.handle {
  cursor: move;
}

.border-b {
  position: absolute;
  padding: 8px 30px 8px 30px;
  background-color: #800080;
  box-shadow: 0 0 8px 1px rgb(0 0 0 / 45%);
  width: 100%;
  z-index: 1000;
  top: 0;
}

select,
option {
  -webkit-font-feature-settings: "ordn";
  -moz-font-feature-settings: "ordn";
  font-feature-settings: "ordn";
}

.header-box {
  display: flex;
  justify-content: space-between;


}

.header-c2 {
  align-self: center;
  display: flex;
}

.menu-list-d {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  margin-top: -6px;

}

.menu-list-d>li>a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-style: italic;
  font-size: 14px;
  margin-left: 25px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  margin-top: 8px;
}

.menu-list-d>li>a:hover {
  color: #fccb20;
  transition: 0.5s;
}

/* menu css open here */
.menu-list-wrp {
  padding: 30px 20px 30px 20px;
  background-color: #fff;
  position: fixed;
  top: 0px;
  height: 100vh;
  z-index: 100000;
  transition: all .3s ease;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

}

.menu-list2 {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: inherit;
  text-align: center;
}

.menu-list2>li>a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  margin-top: 13px;
}

.menu-list2>li>a:hover {
  color: #00d257;
}

.menu-list2>li>a>img {
  width: 18px;
  margin-right: 15px;
}

.cross {
  position: relative;
  left: 180px;
}

.cross>img {
  width: 25px;

}

.mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.menu {
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.slide-menu-right {
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
}




button.close-menu {
  color: #fff;
  display: block;
  margin-left: auto;
  border: none;
  background-color: transparent;
}

.img-close {
  width: 20px;
}

body.smr-open .slide-menu-right {
  left: 0px;
}

.viewdetais-hide {
  display: none;

}

/* menu css closed here */
/* header css closed here */
/* login page css open here */
.login-bg {
  background-color: #efecec;
  min-height: 100vh;
}

.login-database {
  background-color: #800080;
  padding: 6px 10px;
  border-radius: 3px;
  margin-bottom: 20px;
}

.login-database>p {
  font-size: 13px;
  color: #fff;
}

.invalid-p {
  color: #fff;
  font-size: 14px;
  background-color: red;
  padding: 6px 10px;
  display: inline-block;
  border-radius: 4px;
  margin-top: 35px;
}

.login-content {
  width: 350px;
  background-color: #fff;
  border: 1px solid #ce67cc;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: auto;
}

.checkbox-c {
  display: flex;
}

.wrp-label {
  text-align: left;
}

.wrp-label>label {
  display: block;
  font-size: 15px;
  color: #000;
  margin: 0px;
  margin-bottom: 6px;
}

.wrp-label>input {
  display: inline-block;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  color: #000;
  outline: none;
  border: 1px solid #c867c6;
  box-shadow: 0px 0px 8px 1px #1b1b1b40;
}

.wrp-label>input::placeholder {
  color: #b0b0b0;
}

.checkbox-c>p {
  font-size: 13px;
  color: #b0b0b0;
  margin: 4px 0px 0px 8px;
}

.checkbox-c>p>span {
  color: #b0b0b0;
}

.mrt-input {
  margin-top: 20px;
}

.wrp-checkbox {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.login-btn {
  display: inline-block;
  padding: 7px 20px;
  width: 100%;
  background-color: #800080;
  outline: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  border: 2px solid #f167ee;
  margin-top: 35px;
}

.account-content {
  text-align: center;
}

.account-content>p {
  color: #000;
  font-size: 13px;
  margin-top: 30px;
}

.account-content>a {
  color: #00c4d3;
  font-size: 13px;
}

.forgot-p {
  align-self: center;
}

.forgot-p>a {
  font-size: 13px;
  color: #b0b0b0;
}

.account-content>a {
  color: #00c4d3;
  font-size: 14px;
  display: inline-block;
  margin-top: 10px;
}

.user-img {
  text-align: center;
  margin-bottom: 35px;
}

.wrp-login {
  padding-top: 5%;
}

.user-img>img {
  width: 64px;
}

.custom-radios div {
  display: inline-block;
}

.custom-radios input[type="checkbox"] {
  display: none;
}

.custom-radios input[type="checkbox"]+label {
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.custom-radios input[type="checkbox"]+label span {
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 18px;
  border: 1px solid #c867c6;
  box-shadow: 0px 0px 8px 1px #1b1b1b40;
  border-radius: 6px;
}

.custom-radios input[type="checkbox"]+label span img {
  opacity: 0;
  transition: all .3s ease;
}

.custom-radios input[type="checkbox"]#color-1+label span {
  background-color: #3498db;
}

.custom-radios input[type="checkbox"]#color-2+label span {
  background-color: #ffffff;
}

.custom-radios input[type="checkbox"]#color-3+label span {
  background-color: #3498db;
}

.custom-radios input[type="checkbox"]#color-4+label span {
  background-color: #3498db;
}

.custom-radios input[type="checkbox"]:checked+label span {
  opacity: 1;
  background: url(../images/check.png) center center no-repeat;
  width: 18px;
  height: 18px;
  display: inline-block;

}

/* login page css closed here */

a:hover {
  text-decoration: none;
}


/* configure page css open here */
.configer-head-bg {
  background-color: #800080;
  padding: 10px 0px;
  margin-bottom: 40px;
}

.configer-head {
  display: flex;

}

.configer-child1 {
  align-self: center;
}

.configer-child1>h3 {
  color: #000;
  font-weight: 300;
  font-size: 28px;
  margin-left: 15px;

}

.goback-btn {
  display: inline-block;
  padding: 7px 18px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  background-image: linear-gradient(#a060c2, #c360a9);
}

.report-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.report-list-box {
  display: flex;
  background-color: #fff;
  border: 2px solid #c867c6;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 1px #0000002b;
  margin-bottom: 20px;
  text-decoration: none;

}


.report-child2>a {
  color: #000;
  font-size: 15px;
}

.report-child2 {
  margin-left: 15px;
}

/* Basic styles */

.report-child1>input[type="radio"] {
  position: inherit ! important;
  border: 0px;
  width: 20px;
  height: 20px;
  margin-left: 0px;
  margin-top: 4px;
}

.report-child1 {
  align-self: center;
}

.report-child2 {
  align-self: center;
}

/* header css open here */

.header-bg {
  background-color: #800080;
  padding: 12px 25px;
  box-shadow: 0px 0px 8px 1px #00000073;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.wrp-header {
  display: flex;
  justify-content: space-between;
}

.header-child1>h3 {
  margin: 0px;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.mrb-header>h3 {
  color: #800080 ! important;
}

.search-wrp {
  background-color: #670d68;
  border-radius: 8px;
}

.search-wrp>p>input {
  background-color: #670d68;
  font-size: 13px;
  color: #fff;
  border: none;
  width: 202px;
}

.search-wrp>p>input::placeholder {
  color: #b398b3;
}

.search-wrp>p>button {
  display: inline-block;
  padding: 6px 20px;
  color: #fff;
  font-size: 15px;
  border: 2px solid #9b0699;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(90deg, #a060c2, #c360a9);
}

.search-wrp>p>img {
  width: 16px;
}

.search-wrp>p {
  padding-left: 10px;
}

.header-child1 {
  align-self: center;
}

/* header css closed here */


/* dashboard page css open here */
.wrp-dashbord {
  display: flex;
}

.right-section {
  flex: 1;
  margin: 75px 42px 40px 330px;
  min-height: 100vh;
}

.right-section2 {
  flex: 1;
  padding: 90px 150px 40px 145px;
  min-height: 100vh;
}

.right-section3 {
  flex: 1;
  width: 44%;
  margin: auto;
  min-height: 100vh;
}

.header2wrp {
  width: 100%;
  padding-bottom: 6px;

}

.header2list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.containerfixed {
  padding: 80px 30px 10px 30px;
  border-bottom: 1px solid purple;
}

.header2list>li>a {
  color: purple;
  font-size: 14px;
  display: block;
  display: flex;
  padding: 8px 10px;
  transition: .5s;
}

.header2list>li>.active {
  color: #fff;
  border-radius: 4px;
  background-color: purple;
}

.step-smallbox2 {
  display: none;
}


/* dashboard page css closed here */


/* sidebar css open here */
.sidebar {
  width: 285px;
  background-color: #ffffff;
  min-height: 100vh;
  padding: 20px 0px 0px 40px;
  position: fixed;
}

.mrb-header {
  margin-bottom: 28px;
}

.sidebar-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.sidebar-list>li {
  padding-top: 8px;
}

.sidebar-list>li>a {
  color: #800080;
  font-size: 14px;
  display: block;
  padding: 8px 10px;
  display: flex;
  padding-right: 10px;
  transition: 0.5s;
}

.sidebar-list>li>a:hover {
  color: #fff;
  border-radius: 4px;
  transition: 0.5s;
  background-color: #8000808f;
}

.sidebar-list>li>.active {
  color: #fff;
  border-radius: 4px;
  background-color: #800080;
}

.sidebar-icon-img>svg {
  font-size: 20px;
  margin-right: 5px;
}

.sidebar-icon-img img {
  width: 14px;
  margin-right: 15px;
}

.sidebar-icon {
  display: flex;
  color: #fff;
  font-size: 16px;
}

/* sidebar css closed here */


/* filter css open here */

.reset-wrp {
  display: flex;
  justify-content: space-between;
}

.reset-child1>p {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}

.reset-child2>button {
  display: inline-block;
  padding: 6px 20px;
  color: #fff;
  font-size: 15px;
  border: 2px solid #e367e0;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  background: linear-gradient(90deg, #a060c2, #c360a9);
}

.reset-child1 {
  align-self: center;
}

.wrp-step-box {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 40px;
  margin-bottom: 10px;
}

.go-wrp-step-box {
  justify-content: center;
}

.step-box {
  width: 32%;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px;
  height: 165px;
  position: relative;
  border: 2px solid #c867c6;
  margin-right: 20px;
  box-shadow: 1px 1px 8px 1px #00000052;
}

.step-box:nth-child(3) {
  margin-right: 0px;
}

.step-box_hight {
  height: 150px ! important;
}

.step-box_hight2 {
  height: 102px ! important;
  margin-right: 0px ! important;
}

.step-box_hight3 {
  height: 102px ! important;

}

.view-session {
  width: 49%;
}

.BackGouser {
  width: 49%;
}

.checkbox-wrp {
  display: flex;

}

.audiolist {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.audiolist>li {
  margin-top: 5px;
  margin-bottom: 20px;
}

.audiolist>li>p {
  margin-bottom: 5px;
}

.audiolist>li>audio {
  display: inline-block;
  width: 88%;
  vertical-align: middle;
  margin-right: 15px;
}

.audiolist>li>a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  border-radius: 100px;
  background-color: #800080 ! important;
}

.audiolist>li>a>i {
  color: #fff;
  font-size: 15px;
}

.caption-cheeckbox>p {
  color: #000;
  font-size: 14px;
  margin-top: 3px;
}

label {
  margin: 0px;
}

.redious {
  border-radius: 50% ! important;
}

.caption-cheeckbox {
  align-self: center;
  margin-left: 5px;
}

.main-checkbox {
  display: flex;
  justify-content: space-between;
}

.select-client>select {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  font-weight: 600;
  border-radius: 8px;
  color: #6c6c6c;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  padding: 8px;
  outline: none;
}

.selected-bold {
  font-weight: 600;
}

.select-client {
  margin-top: 15px;
}

.step-box>p {
  color: #000;
  font-size: 14px;
}

.step-smallbox-wrp {
  display: flex;
  justify-content: space-between;
}

.step-smallbox {
  margin: auto;
  margin-top: 55px;
}

.smallboxgodevice {
  margin-top: 39px;
}

.step-smallbox>p {
  width: 120px;
  height: 42px;
  line-height: 38px;
  font-size: 16px;
  border-radius: 8px;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
  margin: auto;
  position: relative;
  top: -22px;
}

/* filter css closed here */


/* create page css open here */
.create-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.create-list>li {
  margin-top: 15px;
}

.create-list-box>a {
  display: inline-block;
  text-decoration: none;
  width: 100%;
  color: #000;
  font-size: 14px;
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 1px 1px 8px 1px #00000029;
}


/* create page css closed here */


/* tab css open */


.tabs {
  padding: 0px;
}


/* .active {
  background: linear-gradient(90deg, #fff, #fff);
} */



.content-tabs {
  flex-grow: 1;
}

.content {
  background: white;
  width: 100%;
  height: 100%;
  display: none;
}

.active-content {
  display: block;
}

/* tab css closed */


/* subscription management page css open here */
.software-updated-wrp {
  display: flex;
  margin-top: 0px;
}

.radio-input {
  margin-left: 30px;
}

.no-mr {
  margin-right: 10px;
}

.software-updt-child1>h3 {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin: 0px;
}

.paragraphgo {
  margin-top: -9px;
}

.paragraphgo>p {
  margin: 0px;
  color: #000;
}

.software-updt-child1 {
  align-self: center;
  margin-right: 20px;
}

.subscription-content {
  margin-top: 30px;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
}

.notification-c>p {
  color: #000;
  font-size: 14px;
  margin-top: 7px;
  margin-bottom: 15px;
}

.notify-step-p>p {
  color: #000;
  font-size: 14px;
  margin-top: 5px;
}

.notify-step-p>p>a {
  color: #000;
  font-weight: 600;
  display: inline-block;
  margin-top: 10px;
}

.anual-renew-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-top: 25px;
}

.goanual-renew-list {
  margin-top: 30px ! important;
}

.renew-wrp {
  margin-top: 18px;
}

.renew-wrp>p {
  font-size: 14px;
  color: #000;
  margin: 0px;
}

.anual-renew-list>li>h3 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-bottom: 10px;
  margin-bottom: 0px;
}
.anual-head{
  margin-top: 20px;
}
.anual-renew-list>li>p {
  font-size: 14px;
  color: #000;
  margin-top: 5px;
}

.subscription-content>h3 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}

/* subscription management page css closed here */

/* demo data page css open here */
.demodata-bg {
  min-height: 100vh;
}

.wrp-r-listbox {
  display: flex;
  justify-content: space-between;
}

.report-list-box {
  width: 49.5%;
}

.head-demoreport {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

}

.head-demoreport>h3 {
  color: #000;
  font-weight: 300;
  font-size: 28px;
  align-self: center;
}

/* data data page css closed here */



/* uploadclient form page css open here */

.trainerbox {
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
  height: 135px;
  margin-top: 20px;
}

.trainer-c {
  background-color: #800080;
  padding: 3px 0px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  border: 1px solid #800080;
}

.trainer-c>p {
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.padding-box {
  padding: 10px;
}

.padding-box>input {
  width: 100%;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  color: #6c6c6c;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  padding: 6px;
  outline: none;
}

.mrt-select {
  margin-top: 39px;
}

.client-submit-btn {
  text-align: center;
  margin-top: 40px;
}

.client-submit-btn>button {
  display: inline-block;
  width: 100%;
  padding: 8px 18px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  border-radius: 8px;
  border: none;
  outline: none;
  background-image: linear-gradient(#a060c2, #c360a9);
}

.heading-mobile {
  display: none;
}

/* uploadclient form page css closed here */


/* edit profile page css open here */
.wrp-head-profile {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.head-demoreportgo {
  display: flex;
  margin-top: 10px;
}

.goprofileedit>h3 {
  margin-left: 15px ! important;
}

.client-info-c>h3 {
  margin-left: 15px;
}


.wrp-editprofile {
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
}

.question-child1 {
  display: flex;
  width: 120px;
  justify-content: space-around;
}

.wrp-question {
  display: flex;
}

.question-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.question-child2>p {
  font-size: 15px;
  color: #000;
}

b,
strong {
  font-weight: 600;
}

.question-list>li {
  margin-bottom: 15px;
}

.edit-stoke {
  width: 100%;
  height: 1px;
  background-color: #c588c3;
}

.edit-input-wrp {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.edit-input>p {
  font-size: 14px;
  color: #000;
}

.edit-input {
  width: 32.3%;
  position: relative;
}

.pass-eye3 {
  color: #800080;
  position: absolute;
  top: 39px;
  font-size: 18px;
  right: 14px;
  z-index: 1;
  cursor: pointer;
}

.filter-mrb {
  margin-bottom: 20px;
}

.plus-icon>i {
  color: #800080;
}

.edit-input>input {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  outline: none;
  border: 1px solid #c867c6;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 13px;
  color: #000;
  position: relative;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.edit-input>input::placeholder {
  color: #000;
}

.address-input {
  width: 100% ! important;
}

.select-client5>select {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  outline: none;
  border: 1px solid #c867c6;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 13px;
  color: #000;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.wrp-city-input>.edit-input {
  width: 24%;
}

.save-btn {
  margin-top: 25px;
}

.save-btn>button {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  color: #fff;
  font-size: 15px;
  border: 2px solid #e367e0;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  outline: none;
  background-color: #800080;
  box-shadow: 1px 1px 8px 1px #00000052;
}

/* edit profile page css closed here */


/* bank form page css open here */
.table-box {
  width: 100%;
}

.MuiTableHead-root>tr>th {
  background-color: #800080 ! important;
  padding: 8px 15px;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color: rgba(255, 255, 255, 0.54) ! important;
  opacity: 1;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #fff ! important;
}

.MuiTableCell-head {
  color: #fff ! important;
}

.MuiTableSortLabel-root:hover {
  color: #fff ! important;
}

.MuiPaper-elevation2 {
  border-radius: 8px ! important;
  background-color: #fff;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052 ! important;
}

.filter-top {
  margin-top: -15px;
  margin-bottom: 20px;
}

.downloadimg {
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
}

.downloadimg>img {
  width: 22px;
}

.MuiTableCell-root {
  border-bottom: 1px solid #f378f1;
  font-size: 14px ! important;
}

/* bank form page css closed here */

/* client information page css open here */
.client-info-c>h3 {
  color: #000;
  font-weight: 300;
  font-size: 28px;
}

.goclient-info-c {
  display: flex;
  margin-bottom: 20px;
}

.goclient-info-c>h3 {
  color: #000;
  font-weight: 300;
  font-size: 28px;
  margin-left: 15px;
}

.client-info-box {
  border-radius: 8px;
  background-color: #fff;
  padding: 0px 20px 20px 20px;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
}

.client-input>p {
  font-size: 14px;
  color: #000;
  position: relative;
}

.pass-eye2 {
  color: #800080;
  position: absolute;
  top: 57px;
  font-size: 18px;
  right: 25px;
  z-index: 1;
  cursor: pointer;
}

.client-input>input {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  outline: none;
  border: 1px solid #c867c6;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 13px;
  color: #000;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.client-input {
  margin-top: 20px;
}

.client-input>select {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  outline: none;
  border: 1px solid #c867c6;
  border-radius: 6px;
  padding: 9px 15px;
  font-size: 13px;
  color: #000;
  background-color: #fff;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.client-input>textarea {
  display: inline-block;
  width: 100%;
  margin-top: 5px;
  outline: none;
  border: 1px solid #c867c6;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 13px;
  color: #000;
  box-shadow: 1px 1px 8px 1px #00000029;
}
.complainttextarea{
  height: 45px;
}
.allowclientinput{
  padding: 11px 15px! important;
}

.go-back>a {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  outline: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  background-color: #800080;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.create-btn>button {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  outline: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  border: none;
  background-color: #800080;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.create-btn>a {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
  outline: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  border: none;
  background-color: #800080;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.mrb-option {
  margin-bottom: 20px;
}

.lactive {
  display: inline-block;
  font-weight: 400;
  color: #000;
  font-size: 14px;
  vertical-align: middle;
  margin-left: 8px;
}

.choose-trainer {
  text-align: center;
  padding: 10px 30px;
  border-radius: 8px;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052 ! important;
  margin-bottom: 30px;
  margin-top: 20px;
}

.choose-trainer>label {
  display: inline-block;
  margin-right: 15px;
}

.choose-trainerselectopt {
  display: inline-block;
  font-weight: 600;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  color: #6c6c6c;
  font-size: 14px;
  padding: 8px 20px;
  outline: none;
}

.choose-trainerselectopt>option {
  font-weight: 600;
}

.add-clients>h3 {
  font-size: 18px;
  color: #000;
  margin-top: 20px;
}

/* client information page css closed here */

/* creategroupsetionreport page css open here */
.reportconfig-heading {
  display: flex;

}

.config-head {
  margin-left: 15px;
}

.config-head>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 28px;
}

.group-pass-wrp {
  display: flex;
  justify-content: space-between;
}

.group-pass-child {
  width: 48%;
}

.reportlist {
  margin-top: -12px ! important;
}

.wrp-grouppreconfig {
  display: flex;
}

.grouppreconfig-head {
  align-self: center;
  margin-left: 15px;
}

.grouppreconfig-head>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 28px;
}


.groupreport-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
  margin-top: 20px;
}

.groupreport-list>li>a {
  display: inline-block;
  text-decoration: none;
  width: 100%;
  color: #000;
  font-size: 15px;
  padding: 10px;
  border: 1px solid #c867c6;
  box-shadow: 0px 0px 4px 0px #00000052;
  margin-bottom: 15px;
  border-radius: 8px;

}

.groupreport-list-head>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 28px;
}

.gogroupreport-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px 20px 20px 20px;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
}

.gogroupreport-list>li {
  display: inline-block;
  text-decoration: none;
  width: 100%;
  color: #000;
  font-size: 15px;
  padding: 10px;
  border: 1px solid #c867c6;
  box-shadow: 0px 0px 4px 0px #00000052;
  margin-bottom: 15px;
  border-radius: 8px;
  cursor: pointer;
}

.gogroupreport-list>li>a {
  text-decoration: none;
  color: #000;
}


.reportlist>li {
  position: relative;
}

.reportlist>li>input {
  position: absolute;
  top: 14px;
  left: 12px;
}

.reportlist>li>input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
}

.activelist>input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: purple;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}


.choose-signals {
  background-color: #80008000;
  border: 2px solid #c867c6;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 20px;
}

.choose-signals>p {
  color: #800080;
  font-weight: 600;
  font-size: 15px;
}

.custom-flexs {
  display: flex;
  margin-top: 10px;
}

.wrp-demodatareport {
  display: flex;
  margin-bottom: 20px;
}

.headwrpdemodatareport-head {
  align-self: center;
  margin-left: 15px;
}

.headwrpdemodatareport-head>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 28px;
}

/* creategroupsetionreport page css closed here */


/* createmultisession page css open here */
.signals-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.wrp-signal-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.signal-c-child {
  display: flex;
}

.caption-signal>p {
  margin: 0px;
  font-size: 14px;
  color: #000;
  margin-top: 4px;
  margin-left: 5px;
}

.signals-list>li {
  border-bottom: 1px solid #c867c6;
  padding-bottom: 10px;
}

.mrt-sessions {
  margin-top: 30px;
}

.head-demoreport>p {
  margin-top: 10px;
  font-size: 16px;
}

/* createmultisession page css closed here */


/* charttable page css open here */
.range-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.wrp-charttable {
  color: #000;
  padding: 0px 2% 55px 2%;
}

.range-content-wrp {
  display: flex;
  justify-content: space-between;
}

.datafileformat-wrp {
  display: flex;
  width: 100%;
}

.datafileformat-child1 {
  width: 50%;
  align-self: center;
}

.datafileformat-child2>input {
  vertical-align: middle;
}

.datafileformat-child1>p {
  color: #212529;
  font-size: 16px;
}

.datafileformat-child2>span {
  display: inline-block;
  margin-left: 5px;
  font-size: 16px;
  vertical-align: middle;
}

.modal-box-wrp {
  max-width: 35%;
}

.range-c-child1>p {
  font-size: 13px;
  color: #000;
}

.range-c-child2 {
  width: 68%;
}

.range-c-child2>span {
  width: 100%;
}

.range-c-child2>input {
  display: inline-block;
  width: 100%;
  outline: none;
  color: #000;
  font-size: 13px;
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 13px;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.wrp-axis {
  display: flex;
  justify-content: space-between;
}

ul.action-list>li {
  margin-right: 15px;
}

ul.action-list {
  justify-content: flex-start;
}


ul.action-list.minsapce>li {
  margin-right: 15px;
}

ul.action-list.minsapce {
  justify-content: flex-start;
}

.full-axis {
  width: 100%;
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 0px 0px 0px 8px;
  margin-right: 0px;
}

.full-axis>input {
  font-size: 13px;
}

.full-axis>input {
  display: inline-block;
  border: none;
  outline: none;
  width: 80%;
  font-size: 13px;
}

.min-axis {
  width: 50%;
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 0px 8px;
  margin-right: 20px;
}

.max-axis {
  width: 50%;
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 0px 8px;
}

.min-axis>input {
  display: inline-block;
  border: none;
  outline: none;
  width: 80%;
  font-size: 13px;
}

.max-axis>input {
  display: inline-block;
  border: none;
  outline: none;
  width: 80%;
  font-size: 13px;
}

.range-c-child1,
.range-c-child2 {
  align-self: center;
}

.modal-header {
  background-color: #800080;
  padding: 6px;
}

.modal-title {
  color: #fff;
  align-self: center;
}

.close {
  font-weight: 300;
  color: #fff;
}

.modal-title>span {
  font-weight: 400 ! important;
  font-size: 16px;
}

.modal-box-wrp>.modal-content .modal-header>button>span {
  color: #fff;
  font-weight: 200;
  position: relative;
  top: 3px;
}

.close {
  opacity: 1 ! important;
}

.range-c-child2>p>input {
  width: 15px;
  height: 15px;
  position: relative;
  top: 3px;
}

.modal-box-wrp .modal-content>.modal-header {
  padding: 0px 6px 2px 6px ! important;

}

.range-c-child2>p>label {
  display: inline-block;
  margin-top: -10px;
  margin-left: 6px;
  font-size: 13px;
  color: #000;
}

.mrl-input {
  margin-left: 15px;
}

.range-c-child2>p>input[type=radio]:checked~.check {
  border: 5px solid #0DFF92 ! important;
}

.min-axis>span {
  font-size: 13px;
}

.min-axis>input {
  font-size: 13px;
}

.max-axis>span {
  font-size: 13px;
}

.range-list>li:first-child {
  margin-top: 0px;
}

.range-list>li {
  margin-top: 15px;
}

.raw-pcos {
  background-color: #e8ecef;
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 2px 8px;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.raw-pcos>p {
  color: #000;
  font-size: 13px;
}

.css-1yn2e29-InputColor {
  height: 18px ! important;
  border: none ! important;
  top: 3px;
}

/* charttable page css closed here */

/* chartheader css open here */
.wrp-chart-header {
  display: flex;
  justify-content: space-between;
  padding: 13px 30px 10px 30px;
  background-color: #800080;
  box-shadow: 0 0 8px 1px rgb(0 0 0 / 45%);
  width: 100%;
}

.wrp-action {
  display: flex;
  justify-content: space-between;
}

.action-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: flex-start;
}

.action-list>li>a {
  color: #fff;
  font-size: 13px;
}

.action-list>li>span>a {
  color: #fff;
  font-size: 11px;
}

.action-opt>p {
  font-weight: 600;
  font-size: 11px;
  color: #fff;
}

.view-opt {
  margin-left: 40px;
}

.view-opt>p {
  font-weight: 600;
  font-size: 11px;
  color: #fff;
}

.select-row {
  margin-right: 10px;
}

.select-row>select {
  background-color: #240123;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  width: 100%;
  padding: 6px 8px;
  outline: none;
}

.selected-raw-c {
  width: 300px !important;
}

.wrp-select-row {
  display: flex;
}

.username-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
}

.username-list>li:first-child {
  margin-left: 0px;
}

.username-list>li {
  margin-left: 15px;
}

.username-list>li>a {
  color: #fff;
  font-size: 12px;
}

.head-demoreportedit>h3 {
  margin-left: 0px;
}

.dashboard-back>a {
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  display: inline-block;
  width: 100%;
  padding: 6px 8px;
  outline: none;
}

.chart-header-c1,
.chart-header-c2,
.chart-header-c3,
.chart-header-c4 {
  align-self: center;
}

/* chartheader css closed here */
.username-list>li>a>i {
  display: inline-block;
  margin-right: 5px;
}

.close-btn {
  display: inline-block;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 15px;
  padding: 6px 10px;
  background: #800080;
}

.close:hover {
  color: #fff ! important;
}

.create-list-boxzoomlink>a {
  padding: 0px 0px ! important;
}

.create-list-box>a>a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  display: block;
  padding: 10px 15px;
}

.addlink-input {
  text-align: center;
  margin: 20px 0px;
}

.openvideo {
  display: block;
  text-align: center;
  margin-bottom: 15px;
}

.openvideo>img {
  width: 60px;
}

.openvideo>a {
  display: block;
  font-size: 15px;
}

.addlink-input>input {
  display: inline-block;
  width: 90%;
  border: 1px solid #0000006b;
  padding: 6px 15px;
  border-radius: 8px;
  font-size: 15px;
}

.deactivate {
  background-color: #ccc;
  cursor: no-drop;
}

.activate {
  background-color: #ccc !important;
  cursor: no-drop !important;
}

.colorsqr {
  height: 15px;
  width: 15px;
  border-radius: 5px;
  margin-top: 4px;
  margin-left: 10px;
}

.chart-w {
  border: 2px solid #ce67cc;
  border-radius: 4px;
  box-shadow: 0px 0px 8px #00000054;
  margin: 15px 0px 0px 0px;
  position: relative ! important;
}

.chart-w>div {
  height: 100% ! important;
}

.top-filter-left {
  position: absolute ! important;
  margin: 0px;
  padding: 0px;
  list-style: none;
  top: 1px;
  left: 8px;
  z-index: 10;
  display: flex;
}

.top-filter-left>li>span {
  display: inline-block;
  font-size: 14px;
  padding: 2px 2px;
  height: 15px;
  border-radius: 4px;
  margin-left: 6px;
  margin-bottom: 15px;
}

.top-filter {
  position: absolute ! important;
  margin: 0px;
  padding: 0px;
  list-style: none;
  top: 5px;
  right: 8px;
  z-index: 10;
  display: flex;
}

.top-filter>li>a {
  display: inline-block;
  border: 1px solid #800080;
  padding: 4px 4px;
  width: 20px;
  height: 18px;
  border-radius: 4px;
  text-align: center ! important;
  margin-left: 6px;
}
.bin_1 a{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #800080;
  padding: 4px 4px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  text-align: center ! important;
}

li{
  list-style: none;
}
.bin_1 i{
  font-size: 11px;
  color: #000;
  /* position: relative;
  top: -7px; */
}
.top-filter>li>a>i {
  font-size: 11px;
  color: #000;
  position: relative;
  top: -7px;

}

.plot-charts {
  width: 100% ! important;
  padding-top: 50px !important;
  height: 100%;
}

.MuiIconButton-root:hover {
  outline: none;
}

.right-filter {
  margin: 0px;
  padding: 0px;
  list-style: none;
  position: absolute;
  top: 25px;
  z-index: 10;
  right: 8px;
}

.right-filter>li>a>i {
  color: #000;
  font-size: 12px;
}

.right-filter>li {
  margin-top: 3px;
  height: 15px;
}

sub {
  bottom: -0.25em;
}

.range-c-child2 {
  width: 60% ! important;
}

/* login loader css */

#loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #dcefff;
  border-top: 2px solid blue;
  animation: rotate 2s infinite ease;
  margin-left: 10px;
  position: relative;
  top: 4px;
}



@keyframes rotate {
  0% {
    transform: none;
  }

  100% {
    transform: rotate(1turn);

  }
}

.wrp-loader {
  width: 100%;
  text-align: center;
}

#loader2 {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #dcefff;
  border-top: 2px solid blue;
  animation: rotate 2s infinite ease;
  margin: auto;
  position: relative;
  top: 4px;
}



@keyframes rotate {
  0% {
    transform: none;
  }

  100% {
    transform: rotate(1turn);

  }
}

/* login loader css */


/* popop css open here */
.modal-content {
  border: 3px solid #4847a3;
  box-shadow: 1px 1px 8px 1px #00000052;
}

.right-circle {
  width: 90px;
  height: 90px;
  line-height: 90px;
  border-radius: 100%;
  border: 3px solid #47c9a2;
  background-color: #fff;
  text-align: center;
  margin: auto;
}

.wrp-delete-btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.cancel-btn1>a {
  display: inline-block;
  padding: 8px 30px;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  background-color: #c1c1c1;
  margin-right: 30px;
  cursor: pointer;
}

.delete-btn1>a {
  display: inline-block;
  cursor: pointer;
  padding: 8px 30px;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  background-color: #f15e5e;
}

.cancel-circle {
  border: 3px solid red ! important;
}

.modal-header>button {
  outline: none;
}

.right-circle>img {
  width: 38px;
}

.modal-p>h4 {
  color: #000;
  font-size: 30px;
  text-align: center;
  margin: 10px 0px;
}

.modal-p>p {
  color: green;
  font-size: 16px;
  text-align: center;
}

.modal-error-p>p {
  color: red;
  font-weight: 500;
  font-size: 18px;
  text-align: center;

}

.modal-head {
  font-size: 14px ! important;
}

.modal-body {
  padding: 10px ! important;
}

.registerdevice-btn {
  margin-top: 50px;
  text-align: right;
}

.registerdevice-btn>a {
  display: inline-block;
  padding: 8px 15px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  background-color: #800080;
}

.modal-p>input {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 6px 15px;
  border: 1px solid #c867c6;
  outline: none;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 1px 1px 8px 1px #00000029;

}

.btn-s-submit {
  text-align: center;
  margin-top: 30px;
}

.btn-s-submit>button {
  border: none;
  outline: none;
  padding: 6px 20px;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  background-color: #800080;
}

/* popop css closed here */

.select-lang-box>select {
  display: inline-block;
  border-radius: 4px;
  padding: 3px 10px;
  font-size: 15px;
  outline: none;
  background-color: #fff;
}

.wrp-email-box {
  padding-top: 10px;
}

.email-box {
  width: 42%;
  margin: auto;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
}

.radio-wrp {
  display: flex;
  justify-content: space-between;
}

.radio-btn>span {
  color: #000;
  font-size: 14px;
}

.radio-btn>input {
  vertical-align: middle;
}

.radio-btn>input[type=radio] {
  width: 15px;
  height: 15px;
}

.select-radio-option>select {
  display: inline-block;
  width: 100%;
  padding: 5px 15px;
  color: #000;
  font-size: 15px;
  border-radius: 6px;
  border: 1px solid #800080;
  margin-top: 20px;
}

.add-radio-btn {
  text-align: center;
}

.add-radio-btn>button {
  display: inline-block;
  border: none;
  outline: none;
  padding: 6px 15px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin-top: 15px;
  background-color: #800080;

}

.input-email>input {
  display: inline-block;
  width: 100%;
  padding: 5px 15px;
  color: #000;
  font-size: 15px;
  outline: none;
  border-radius: 6px;
  border: 1px solid #800080;
  margin-top: 20px;
}

.btn-input {
  text-align: center;
}

.btn-input>button {
  display: inline-block;
  border: none;
  outline: none;
  padding: 6px 15px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #fff;
  margin-top: 15px;
  background-color: #800080;
}

.list-radio {
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-top: 20px;
}

.list-radio>li {
  padding: 5px 15px;
  font-size: 14px;
  color: #000;
  border-radius: 6px;
  border: 1px solid #800080;
  margin-top: 10px;
}


/* list page css open here */
.add-btn-wrp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.head-listing {
  align-self: center;
}

.head-listing>h3 {
  color: #000;
  font-weight: 300;
  font-size: 28px;
}

.add-btn {
  align-self: center;
}

.add-btn>button {
  display: inline-block;
  border: none;
  outline: none;
  padding: 6px 20px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #fff;
  background-color: #800080;
}

/* list page css closed here */


.expridate-filter>select {
  outline: none;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052 ! important;
  font-size: 15px;
}


.wrp-chart-loader {
  display: flex;
  justify-content: center;
  border: 1px solid #00f 33;
}

.loading {
  height: 120px;
  padding: 0px;
  width: 160px;
  margin-top: 30px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 10px;
  box-sizing: border-box;
}

@keyframes loading {
  0% {
    background-color: #cd0a00;
  }

  30% {
    background-color: #fa8a00;
  }

  50% {
    height: 100px;
    margin-top: 0px;
  }

  80% {
    background-color: #91d700;
  }

  100% {
    background-color: #cd0a00;
  }
}

/*@-moz-keyframes loading {
  50% {
    height: 100px;
    margin-top: 0px;
 }
}
@-o-keyframes loading {
  50% {
    height: 100px;
    margin-top: 0px;
 }
}
@keyframes loading {
  50% {
    height: 100px;
    margin-top: 0px;
 }
}
*/
.loading .loading-1 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.loading .loading-2 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.loading .loading-3 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.loading .loading-4 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.loading .loading-5 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 1.25s;
  animation-delay: 1.25s;
}

.loading .loading-6 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.loading .loading-7 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 1.75s;
  animation-delay: 1.75s;
}

.loading .loading-8 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.loading .loading-9 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 2.25s;
  animation-delay: 2.25s;
}

.loading .loading-10 {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.lightbtn {
  background-color: #fff;
  color: #800080;
  border: 1px solid #800080;

}

.darktbtn {
  background-color: #800080;
  color: #fff;
  border: 1px solid #800080;

}

.statTable td {
  padding: 5px 10px;
}

.statTable th {
  padding: 5px 10px;
}

.statTable {
  font-size: 14px;
}

/* assembly report page css open here */
.assembly-box {
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 20px 15px;
  box-shadow: 1px 1px 8px 1px #00000029;
  margin-bottom: 30px;
  margin-top: 20px;
}

.assembly-content>h3 {
  font-size: 22px;
  font-weight: 600;
  color: #000;
}

.assembly-content>p {
  color: #000;
  font-size: 15px;
  margin-top: 15px;
}

.assembly-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-top: 30px;
}

.highlighted>a {
  background: #000;
  color: #fff;
}

.highlighted>a>i {

  color: #fff !important;
}

.plotly-notifier {
  display: none;
}

.assembly-list>li>p {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
}

.checkbox-assemblylist {
  margin: 0px;
  padding: 0px;
  list-style: none;
  margin-top: 30px;
}

.checkbox-assemblylist>li>p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.flowing-paragraph {
  color: #000;
  font-size: 15px;
  margin-top: 20px;
}

.assembly-btn-wrp {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.assembly-btn>a {
  display: inline-block;
  border-radius: 6px;
  padding: 8px 15px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  background-color: #800080;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.mrl-pdf {
  margin-left: 20px;
}

.client-names-wrp {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.underline-label>label {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  text-decoration: underline;
}

.not-underline-label>label {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  /* text-decoration: underline; */
}

.bold-label>label {
  font-size: 16px !important;
  color: #000;
  font-weight: 600;
  /* text-decoration: underline; */
}

.report-input>label {
  font-size: 14px;
  color: #000;

  /* text-decoration: underline; */
}

.report-input>input {
  display: block;
  width: 100%;
  border: 1px solid #c867c6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 14px;
  color: #000;
  box-shadow: 1px 1px 8px 1px #00000029;
  margin-top: 5px;
}

.text-areat-report {
  margin-top: 20px;
}

.text-areat-report>label {
  font-size: 14px;
  color: #000;
}

.text-areat-report>textarea {
  display: block;
  width: 100%;
  border: 1px solid #c867c6;
  border-radius: 4px;
  padding: 7px 15px;
  font-size: 14px;
  color: #000;
  height: 150px;
  box-shadow: 1px 1px 8px 1px #00000029;
  margin-top: 5px;
}

.assembly-btn-wrp2 {
  width: 100% ! important;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding: 10px 0px 10px 0px;
}

.live-section-img {
  margin-top: 25px;
}

.live-section-img>label {
  /* font-size: 14px; */
  color: #000;
}

.live-section-img>img {
  width: 100%;
  margin-top: 15px;
}

.report-notes {
  margin-top: 25px;
}

.report-notes>label {
  /* font-size: 14px; */
  color: #000;
}

.report-notes>p {
  font-weight: 600;
  color: #000;
  border: 1px solid #000;
  font-size: 15px;
  margin-top: 10px;
  padding: 5px 15px;
}

.interview-checklist {
  margin-top: 25px;
}

.interview-checklist>label {
  font-size: 14px;
  color: #000;
}

.interview-checklist>img {
  width: 100%;
  margin-top: 15px;
}

.assessment-record {
  margin-top: 20px;
}

.assessment-record>img {
  width: 100%;
  margin-top: 15px;
}

.live-section-img>label {
  display: inline-block;
  margin-bottom: 10px;
}

.ml-assembly {
  margin-left: 60px;
}

.complete-forms {
  margin: 0px;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: -15px;
}

/* assembly report page css closed here */


/* .ex-chart{
    text-align: center;
    color: #800080;
    margin-top: 10rem;
 
} */

/* .uploadfile{
  text-align: center;
  margin-top: 5rem;
  height: 10px;
  
}
.action-list2{
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  
}

.fa-upload,.fa-file-pdf-o,.fa-table,.fa-question-circle,.fa-clock-o,.fa-download,.fa-file-text{
  color: #fff !important;
}
.files {
  margin: 10px;
  width:50%; 
  margin-left: 36rem;
}
.buttonstyle {
  border-color: #800080;
  color: #800080;
  background-color: transparent;
  border: 1px solid #800080;
  margin-left: 9rem;
}

.create-list-box1 {
  display: inline-block;
  width: 100%;
  color: #000;
  font-size: 14px;
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.need-{
  color: #800080 !important;
} */

.js-plotly-plot .plotly .cursor-crosshair {
  cursor: help !important;
}

.js-plotly-plot .plotly .cursor-ew-resize {
  cursor: grab !important;

}

.cursor-crosshair>.xy>.cursor-ew-resize {
  cursor: col-resize ! important;
}


/* downloader */

.loading2 {
  width: 15rem;
  height: 7.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
}

.loading2 .text {
  font-size: 2rem;
  text-transform: uppercase;
  margin: auto;
}

.dot {
  font-size: 2rem;
  font-weight: 700;
  border-radius: 50%;
  color: #c867c6;
  animation: load 0.5s alternate infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.16s;
}

.dot:nth-child(3) {
  animation-delay: 0.32s;
}

.dot:nth-child(4) {
  animation-delay: 0.48s;
}

.dot:nth-child(5) {
  animation-delay: 0.64s;
}

.dot:nth-child(6) {
  animation-delay: 0.8s;
}

.dot:nth-child(7) {
  animation-delay: 0.96s;
}

@keyframes load {
  from {
    transform: scaleX(1.25);
  }

  to {
    transform: translateY(-2rem) scaleX(1);
  }
}


/* new css */

.step-trainers-box {
  width: 120px;
  height: 42px;
  line-height: 38px;
  border-radius: 8px;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
  margin: auto;
  margin-top: -38px;
}

.step-trainers-box>p {
  font-size: 16px;
}

.backbtn-icon {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #800080;
  transition: 0.5s;
  align-self: center;
}

.backbtn-icon>img {
  width: 15px;
  margin-right: 8px;
}

.backbtn-icon>span {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}



.backbtn-icon:hover {
  background-color: #8000803d;
  transition: 0.5s;
}

.client-info-c {
  display: flex;
  margin-bottom: 20px;
}

.client-info-c-head {
  margin-left: 15px;
}

.client-info-c-head>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 28px;

}

.edittrainerheadingmobile>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 24px;
}

.edittrainerheadingmobile {
  display: none;
}

.multi-lan {
  margin-top: 20px;
}

.mrt-view-upload {
  margin-top: 20px;
}



/* tool tip css open here*/

.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltiptext {
  visibility: hidden;

  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip2:hover .tooltiptext {
  visibility: visible;
}

.notification-c>h3 {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-bottom: 12px;
  margin-top: 25px;
}

.notification-c>h3>span {
  font-weight: 400;
  font-size: 15px;
}

.renewparagraph {
  font-size: 14px;
  color: #000;
  margin: 0px;

}

.wrp-bankform {
  margin-top: 20px;
}

.paragraphgo>p>b>u>a {
  color: #800080;
}

.renewparagraph>b>u>a {
  color: #800080;
}

.form-check-input:checked {
  background-color: #800080;
  border-color: #800080;
}

.anual-renew-list>li>p>b>u>a {
  color: #800080;
}

.mebership-status>p {
  color: #000;
  font-size: 14px;
  margin-top: 20px;
}

.password-input {
  position: relative;
}

.pass-eye {
  color: #800080;
  position: absolute;
  top: 40px;
  font-size: 18px;
  right: 12px;
  z-index: 1;
  cursor: pointer;
}

.forgot-password>a {
  font-size: 13px;
  display: inline-block;
  margin-top: 5px;
  color: #800080;
}

.require-email {
  font-size: 14px;
  color: red ! important;
  margin: 0px;
  margin-top: 3px;
}

/* tool tip css closed here */


/* reset password page css open here */

.reset-input-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.reset-input-list>li {
  margin-bottom: 15px;
  position: relative;
}

.pass-eye4 {
  color: #800080;
  position: absolute;
  top: 10px;
  font-size: 18px;
  right: 12px;
  z-index: 1;
  cursor: pointer;
}

.reset-input-list>li>p {
  font-size: 15px;
  margin-top: 5px;
  color: #800080;
}

.reset-input-list>li>input {
  display: inline-block;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;
  color: #000;
  outline: none;
  border: 1px solid #c867c6;
  box-shadow: 0px 0px 8px 1px #1b1b1b40;
}

.submit-btn-reset>button {
  display: inline-block;
  padding: 7px 20px;
  width: 100%;
  background-color: #800080;
  outline: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  border: 2px solid #f167ee;
  margin-top: 20px;
}

.reset-password-bg {
  background-color: #efecec;
  min-height: 100vh;
  padding-top: 5%;
}

/* resent password page css closed here */


/* configure table css open here */

.pet-2 {
  position: relative;
  top: 3px;
}

.configure-list {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.configure-wrp {
  display: flex;
}

.configure-child2>span {
  display: inline-block;
  color: #212529;
  margin-left: 5px;
  font-size: 16px;
  vertical-align: middle;
}

.configure-child2 {
  width: 65%;
}

.radio-mrl {
  display: inline-block;
  margin-left: 10px;
}

.configure-child2>input {
  vertical-align: middle;
}

.configure-child1>p {
  color: #212529;
  font-size: 16px;
}

.configure-list>li:first-child {
  margin-top: 0px;
}

.configure-list>li {
  margin-top: 10px;
}

.configure-child1 {
  width: 45%;
}

.specialwidth {
  width: 35%;
}

.time-input {
  display: inline-block;
  width: 48%;
  border: 1px solid #000;
  border-radius: 0;
  color: #000;
  font-size: 15px;

}

.time-input-mr {
  margin-right: 8px;
}

.select-option-time {
  display: inline-block;
  width: 100%;
  color: #000;
  font-size: 15px;
  outline: none;
}

.data-point {
  position: relative;
  top: 6px;
}

.configtable-colse-btn {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  font-size: 15px;
  color: #800080;
  padding: 6px 20px;
  background-color: transparent;
  border: 1px solid #800080;
  outline: none;
  border-radius: 4px;
}

/* configure table css closed here */
p.flyleft1 {
  left: -9.5%;
  position: absolute;
  transform: rotate(270deg); 
  top: 46%;
  float: none;
  font-size: 12px;
  background: purple;
  color: #fff;
  padding: 0px 5px;
}
p.flyleft2 {
  position: absolute;
  transform: rotate(270deg);
  left: -4.5%;
  top: 46%;
  float: none;
  font-size: 12px;
  background: purple;
  color: #fff;
  padding: 0px 5px;
}

p.flyleft2.moreflyleft2 {
  left: -4.5%;
}

.table .thead-dark th {
  color: #fff;
  background-color: purple;
  border-color: purple;
}

p.flyleft {
  position: absolute;
  transform: rotate(270deg);
  left: -19.5%;
  top: 46%;
  float: none;
  font-size: 12px;
  background: purple;
  color: #fff;
  padding: 0px 5px;
}

p.flyleft.moreflyleft {
  left: -23.5%;
}


/* multidata report css open here */

.multidatareport-list {
  margin: 0px;
  padding: 0px;
  list-style: none;

}

.multidatareport-list>li {
  font-size: 15px;
  display: block;
  margin-top: 8px;

}

.multidatareport-list>li>span {
  display: inline-block;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 600;
}

.multidatareport-list>li>a {
  color: #800080;
  display: inline-block;
}

.select-setting>select {
  display: block;
  width: 100%;
  padding: 5px 5px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;

}

.wrp-select-setting {
  display: flex;
  justify-content: space-between;
}

.label-setting {
  width: 38%;
  align-self: center;
}

.select-setting {
  width: 60%;
  align-self: center;
}

.btn-close {
  align-self: center;
  width: 20px ! important;
  height: 20px ! important;
  opacity: .7 ! important;
  background: transparent url('../images/closex.svg') center/13px auto no-repeat ! important;
}

.list-exort-content>span {
  display: inline-block;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 600;
}

.list-exort-content>a {
  color: #800080;
  display: inline-block;
}

.wrp-accourdian-head>button {
  padding: 6px 12px;
}

.list-exort-content>a {
  font-size: 15px;
}

.accordion-button:not(.collapsed) {
  color: #800080 ! important;

}

.mrt-radiobtn {
  margin-left: 15px ! important;
}

.excel-wrp>input {
  vertical-align: middle;
  display: inline-block;
  margin-left: 8px;
}

.excel-wrp>label {
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  margin-left: 8px;
}

.accordion {
  border-bottom: 1px solid #0000008c ! important;
  border-radius: 4px ! important;
}

/* multiple report css closed here */


/* popup css open here */

.imgexportdata>img {
  width: 100%;
}

.modal-box-wrpaction {
  max-width: 45% ! important;
}

.range-slider__wrap>input {
  margin-top: -10px;
}

.wrp-daterangepicker {
  display: flex;
  justify-content: space-between;
}

.head-rangedate {
  align-self: center;
}

.datePickertwrp>.head-demoreport {
  margin-bottom: 0px;
}

.wrp-daterangepicker>.back-icon-wrp {
  align-items: center;
}

.DateInput_input {
  font-size: 17px ! important;
  font-weight: 400 ! important;
  line-height: 20px ! important;
}

/* popup css closed here */


.datePickertwrp {
  display: flex;
  justify-content: space-between;
}

.datareport-content>h3 {
  color: #000;
  font-weight: 300;
  font-size: 28px;
  margin-left: 15px;
  align-self: center;
}

.head-demoreport5 {
  display: flex;
  align-self: center;
  margin-top: 10px;
}

.head-rangedate>div>p {
  color: #000;
  font-size: 16px;
  margin-bottom: 5px;
}

.MTableHeader-header-14 {
  z-index: 0 ! important;
}

.rs-picker-toggle-wrapper {

  width: 100% ! important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #800080 ! important;
  background-color: var(--rs-bg-active);

}

.rs-btn-primary {
  background-color: #800080 ! important;
}

.rs-btn-link {
  color: #800080 ! important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #800080 ! important;
}

.MuiToolbar-gutters {
  padding-left: 15px ! important;
}

.menu-container {
  width: 58%;
  margin: auto;
  padding-top: 120px;
}

.grid-menu {
  display: block;
  width: 100%;
  text-align: center;
  height: 180px;
  border-radius: 4px;
  margin-bottom: 25px;
  border: 1px solid #800080;
  text-decoration: none;
  padding-top: 33px;
  transition: 0.5s;
  text-decoration: none;
}


.grid-menu>div>svg {
  fill: #800080;
  font-size: 60px;

}

.grid-menu:hover {
  transition: 0.5s;
  background-color: #800080;
  transform: scale(1.1);
}

.grid-menu:hover.grid-menu>p {
  color: #fff ! important;

}

.grid-menu:hover.grid-menu>h3 {
  color: #fff ! important;

}

.grid-menu:hover.grid-menu>div>svg {
  fill: #fff ! important;

}

.sidebar-icon>svg {
  fill: #fff;
  font-size: 20px;
  margin-right: 5px;
}

.grid-menu>h3 {
  color: #800080;
  font-size: 20px;
  font-weight: 600;
  margin: 0px;
  margin-top: 15px;
}

.grid-menu>p {
  color: #800080;
  font-size: 14px;
  margin: 0px;
}

.wrp-step-box6 {
  display: inherit ! important;
  margin-top: 100px;
}

.wrp-step-box7 {
  margin-top: 0px ! important;
}

.wrp-step-box6>.step-box {
  margin: auto;

}

.toggle-slide-right {
  display: block;
}

.BackGouser {
  text-align: center;
  align-self: center;

}

.BackGouser>a {
  color: #fff;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #800080;
  background-color: #800080;
  border-radius: 8px;
  padding: 8px 15px;
  box-shadow: 1px 1px 8px 1px #00000029;


}

.BackGouser2 {
  text-align: center;
}

.BackGouser2>a {
  color: #fff;
  display: inline-block;
  width: 230px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #800080;
  background-color: #800080;
  border-radius: 8px;
  padding: 8px 15px;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.wrp_backbtn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  margin-top: 25px;
}

.BackGouser>a>svg {
  margin-right: 10px;
}

.wrp-config-heading {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.view-session {
  text-align: center;
}

.view-session>a {
  display: inline-block;
  width: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border: 2px solid #800080;
  background-color: #800080;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.diactivatedcontinue>a {
  color: #000 ! important;
  background-color: #dddddd ! important;
  border: 2px solid #dddddd ! important;
  cursor: no-drop ! important;
}

.profilelist>a {
  display: inline-block;
  width: 100%;
  color: #000;
  font-size: 14px;
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 10px;
  text-decoration: none;
}

.wrpSectioncontent {
  width: 44%;
  margin: auto;
  margin-top: 25px;
}

.rs-picker-menu {
  z-index: 100000 ! important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  border: 2px solid #c867c6;
  margin-right: 20px;
  box-shadow: 1px 1px 8px 1px #00000052;
}

.sectionlist {
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;

}

.viewbtnscroll {
  background-color: transparent;
  border-bottom: 2px solid #c867c6;
  border-left: 2px solid #c867c6;
  border-right: 2px solid #c867c6;

  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 100%;
  position: relative;
  z-index: 10;
  box-shadow: 0 5px 5px -5px #00000052, -5px 0 5px -5px #00000052, 8px 0 8px -5px #00000052;
  /* box-shadow: 0px 1px 8px 1px #00000052; */
  top: -6px;
  padding-top: 10px;
}

#scrolllist {
  transition: 0.5s;
}


.sectionlist2 {
  width: 100%;
  height: 136px;
  overflow-y: scroll;
  padding-right: 10px;
}

.sectionlist>li>input {
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
  margin-top: -3px;
}

.sectionlist>.activelist>input {
  vertical-align: middle;
  margin-top: -2px;
}

.sectionlist2::-webkit-scrollbar-track {
  border: 1px solid #000;
  padding: 2px 0;
  background-color: #800080;
  border-radius: 32px;
}

.sectionlist2::-webkit-scrollbar {
  width: 10px;
}

.sectionlist2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #fff;
  border: 1px solid #000;
}

.inputcircle {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #800080;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: -4px;
}

.sectionlist>li {
  width: 49%;
  height: 38px;
  margin-bottom: 10px;
  border: 1px solid purple;
  padding: 9px 10px 10px 10px;
  border-radius: 6px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.stokelist {
  width: 100%;
  height: 1px;
  background-color: purple;
}

.wrp-step-box6>.step-box {
  width: 44%;
  height: 105px;
}

.wrp-step-box6>.step-box>.smallboxgodevice {
  margin-top: 25px;
}

.session-heading {
  width: 145px;
  height: 42px;
  line-height: 38px;
  border-radius: 8px;
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 2px solid #c867c6;
  box-shadow: 1px 1px 8px 1px #00000052;
  margin: auto;
  position: relative;
  z-index: 100;
  margin-top: 20px;
  text-transform: uppercase;
}

.session-heading>p {
  margin: 0px;
  font-size: 16px;
}

.sectionlist>p {
  color: #000;
  font-size: 14px;
  margin-bottom: 5px;
  margin: auto;
  margin-top: 10px;
}

.activelist {
  color: #fff ! important;
  background-color: purple;
}

.activelist>a {
  color: #fff ! important;
}

.receive-paragraph {
  margin-top: 20px;
}

.receive-paragraph>p {
  font-size: 14px;
  color: #000;
  margin: 0px
}

.anual-renew-list>li>h3>span {
  font-weight: 400;
}

.wrpgofiltter {
  position: relative;
  top: -30px;

}

.profilelist {
  width: 65%;
  margin: auto;
}


/* user 5 and 6 menu css  open here*/

.sidebar2 {
  width: 100%;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 1000;
  transition: 0.5s;
}

.sidebar2.active {
  left: 0;
}

.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  position: relative;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.closebutton {
  position: absolute;
  top: 40px;
  right: 60px;
  background-color: transparent;
}



/* user 5 and 6 menu css open here */
.fixed-box2 {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border: 2px solid #c867c6;
  /* box-shadow: 1px 1px 8px 1px #00000052; */
  padding: 23px 0px 10px 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -23px;
  box-shadow: 0 -5px 8px -5px #00000052, -5px 0 8px -5px #00000052, 8px 0 8px -5px #00000052;
}

.fixed-box {
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border: 2px solid #c867c6;
  border-radius: 8px;
  box-shadow: 1px 1px 8px 1px #00000052;
  padding: 23px 10px 10px 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -23px;
}

.validemail {
  color: red ! important;
  margin-top: 5px;
}


.goeditprofile {
  width: 49.3% ! important;
}

.groupreport-list-head {
  display: flex;
  justify-content: space-between;
}

.wrpprofilelistbox {
  width: 35% ! important;
  margin: auto;
  margin-top: 60px;
}

.text-center {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}

.wrp-gotodashboardbtn {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}

.wrp-gotodashboardbtn>a {
  color: #fff;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #800080;
  background-color: #800080;
  border-radius: 8px;
  padding: 8px 15px;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.wrp-gotodashboardbtn>a>svg {
  margin-right: 10px;
}

.wrp-gotodashboardbtn2 {
  width: 35%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
}

.wrp-gotodashboardbtn2>a {
  color: #fff;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #800080;
  background-color: #800080;
  border-radius: 8px;
  padding: 8px 15px;
  box-shadow: 1px 1px 8px 1px #00000029;
}

.wrp-gotodashboardbtn2>a>svg {
  margin-right: 10px;
}

.radio-input>span {
  vertical-align: middle;
}

.upgradeparagraph {
  margin-top: 30px ! important;
}

.wrpselect-rowgo {
  display: flex;
  justify-content: space-between;
}

.select-rowgo {
  width: 49% ! important;
}

.dashboard-back-mobile {
  width: 49%;
}

.dashboard-back-mobile .dashboard-back {
  width: 100%;
}

.dashboard-back-mobile .dashboard-back>a {
  width: 100%;
  padding: 7px 8px;
}

.wrpselect-rowgo {
  display: none;
}

.range-list>li>div>div>select {
  border: 2px solid #c867c6;
  border-radius: 8px;
  padding: 0px 8px 0px 8px;
}


/* privacy policy page css open here */

.headingprivacypolicy>h1 {
  margin: 0px;
  font-size: 28px;
  color: #000;
  line-height: 42px;
}

.wrp-privacypolicy>p {
  margin: 0px;
  font-size: 15px;
  color: #000;
}

.listprivacy>p {
  margin: 0px;
  font-size: 15px;
  color: #000;
}

.listprivacy>li {
  color: #000;
  font-size: 15px;
}

.wrp-privacypolicy {
  padding: 40px 20px;
  width: 90%;
  margin: auto;
}

.pravicy-btn {
  margin-top: 10px;
}

.requiredpdfname {
  text-align: center;
  color: red;
  font-size: 15px;
}

.back-icon-wrp {
  display: flex;
  justify-content: space-between;
}

.wrp-view-uploadclientform {
  display: flex;
  margin-bottom: 20px;
}

.uploadclientformheading {
  margin-left: 15px;
}

.uploadclientformheading>h3 {
  color: #000;
  font-weight: 300;
  font-size: 28px;
  align-self: center;
}

.uploadclientformheading>h3 {
  color: #000;
  font-weight: 300;
  font-size: 28px;
  align-self: center;
}

.ownerlistserialkey {
  margin: 0px;
  padding: 0px;
  list-style: decimal;
  padding-left: 15px
}

.ownerlistserialkey>li {
  font-size: 14px;
  color: #000;
}

.oflinereportconfig-heading-wrp {
  display: flex;
}

.oflinereportconfig-head {
  margin-left: 15px;
}

.oflinereportconfig-head>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 28px;
}

.gopopuphead {
  text-align: center;
}

.gopopuphead>h3 {
  color: #800080;
  font-size: 22px;
  font-weight: 600;
  margin: 0px;
  position: relative;
  top: -40px;
}


.gopopuphead2 {
  text-align: center;
}

.gopopuphead2>h3 {
  color: #800080;
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
  position: relative;
  top: -10px;

}

.gopopupheadm>h3 {
  text-align: center;
  color: #800080;
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
}

.nodevice {
  color: #000;
}

.close>span:hover {
  color: #fff ! important;
}

.close:hover {
  color: #fff ! important;
}

.filter-main {
  margin-bottom: 30px;
}

.demodatareport-list-box {
  display: flex;
}

.demodatareport-list-box>.report-child2>a {

  background-color: #fff;
  border: 2px solid #c867c6;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 8px 1px #0000002b;
  margin-bottom: 20px;

}

.report-child2>span {
  display: inline-block;
  font-size: 15px;
  color: #000;
}

.reportcircle {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid purple;
  vertical-align: middle;
}

.select-client>select>option {
  font-weight: 600;
  border-radius: 8px;
  color: #6c6c6c;
}

.view-session-reportdata {
  display: flex;
}

.view-session-reportHeading {
  margin-left: 15px;
}

.view-session-reportHeading>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 28px;
}

.blankheadingform>h3 {
  color: #000;
  margin: 0px;
  font-weight: 300;
  font-size: 28px;
}

.heading-blankform {
  display: flex;
}

.blankheadingform {
  margin-left: 15px;
}

.requirdfield {
  color: red;
  margin: 0px;
  font-size: 15px;
  text-align: center;
  position: relative;
  top: -5px;
}

.spanlabel {
  vertical-align: middle;
}

.ant-radio {
  vertical-align: middle;
}

.sessionp {
  text-align: right;
  margin: 0px;
  font-size: 15px;
  margin-top: 10px;

}

.sessionp>a {
  color: #800080;
}

.step-smallboxmanage {
  margin-top: 25px ! important;
}

.gosessionp {
  width: 100%;
  text-align: right;
  padding-right: 22px;
  margin: 0px;
  position: relative;
  top: -8px;
  font-size: 15px;
}

.gosessionp>a {
  color: #800080;
}

.gowrpmanagesession {
  margin-top: 120px;
}

.backwrpicon {
  width: 44%;
  margin: auto;
  margin-bottom: 30px;
}

.nodatafound {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  text-align: center;
}
.multi-chart-header-c4{
  align-self: center;
}
.multi-chart-header-c5{
  align-self: center;
}
.nopdffound > p{
  margin: 0px;
  color: #000;
  font-size: 16px;
  text-align: center;
}
.defaultdropdown{
  width: 110px! important;
}



/* subscription recharge new page css open here */

.checkboxrechargewrp{
  display: flex;
}
.rechergecheckbox{
  margin-right: 15px;
  margin-top: 5px;
}
.rechargesubscriptlist > li{
  margin-top: 20px;
}
.rechergecheckbox > p > span{
  display: inline-block;
  margin-left: 6px;
}

.actiondownload-pdfreport{
  background-color: transparent;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  margin-top: 3px;
  display: inline-block;
  width: 96%;
  padding: 5px 4px;
  outline: none;
}
.actiondownload-pdfreport > i{
  display: inline-block;
  margin-right: 5px;
}

/* subscription recharge new page css closed here */