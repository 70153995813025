body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1 {
  text-align: center;
  color: #0066cc;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.form-row {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
}

.form-row label {
  font-weight: bold;
  margin-right: 5px;
}

.form-row .underline {
  flex: 1;
  border-bottom: 1px solid #000;
  margin-right: 10px;
}

.checkbox-group {
  margin-top: 15px;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.checkbox-item {
  display: flex;
  align-items: flex-start;
}

input[type="checkbox"] {
  margin-right: 5px;
  margin-top: 3px;
}

.checkbox-label {
  flex: 0 0 auto;
  width: 150px;
}

.checkbox-underline {
  flex: 1;
  background-color: #f1f4ff;
  border: none;
  border-bottom: 2px solid #444;
}

.section-title {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.client-info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.client-info-item {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}

.type-checkboxes {
  display: flex;
  align-items: center;
  gap: 10px;
}

.smaller-note {
  font-size: 11px;
  font-style: italic;
}
